import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const WishlistModal = ({
  show,
  handleClose,
  wishlist,
  handleRemoveFromWishlist,
}) => {
  const { t } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseImageModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="support-modal-dialog"
      >
        <Modal.Body>
          <div className="form-container">
            <h2 className="modal-title">{t("wishlist")}</h2>
            {wishlist.length > 0 ? (
              wishlist.map((item) => (
                <div key={item.id} className="cart-item">
                  <img
                    src={item.imageUrl}
                    alt={item.category}
                    className="cart-item-image"
                    onClick={() => handleImageClick(item.imageUrl)}
                    style={{ cursor: "pointer" }}
                  />
                  <div className="cart-item-details">
                    <h3 className="item-category">{item.category}</h3>
                    <p>
                      <strong>{t("color")}:</strong> {item.color}
                      <br />
                      <strong>{t("material")}:</strong> {item.material}
                    </p>
                    <div className="cart-item-actions">
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveFromWishlist(item.id)}
                        className="btn-cart-primary button"
                        data-text={t("removeFromWishlist")}
                      >
                        <svg viewBox="0 0 448 512" className="svgIcon">
                          <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                        </svg>
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>{t("noItemsWishlist")}</p>
            )}
            <Button
              variant="secondary"
              onClick={handleClose}
              className="btn-cart-secondary form-submit-btn"
            >
              {t("close")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal for showing larger image */}
      <Modal show={!!selectedImage} onHide={handleCloseImageModal} centered>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Larger View"
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WishlistModal;
