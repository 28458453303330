import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, getDocs, doc, deleteDoc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBhEAWPr9FR2e7yLrT_o12p3zz7GJZRvKk",
  authDomain: "wardrobe-bf2fd.firebaseapp.com",
  projectId: "wardrobe-bf2fd",
  storageBucket: "wardrobe-bf2fd.appspot.com",
  messagingSenderId: "699902116981",
  appId: "1:699902116981:web:a90867345f8ba6971a643c",
  measurementId: "G-EPGCP7Z3NQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Initialize providers
const googleProvider = new GoogleAuthProvider();

const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');


export { 
  db, 
  auth, 
  storage, 
  collection, 
  addDoc, 
  getDocs, 
  doc, 
  deleteDoc, 
  updateDoc, 
  getDoc, 
  setDoc, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  signOut,
  googleProvider,
  appleProvider,
  signInWithPopup,
  OAuthProvider,
};