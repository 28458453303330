// userUtils.js

import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './Data/firebase';

export const createUserDocument = async (user) => {
  if (!user) return;

  const userRef = doc(db, 'users', user.uid);
  const userSnap = await getDoc(userRef);

  if (!userSnap.exists()) {
    const { email, displayName, photoURL } = user;
    const createdAt = new Date();

    try {
      await setDoc(userRef, {
        email,
        displayName: displayName || email.split('@')[0], // Use email as displayName if not provided
        photoURL,
        createdAt,
        wardrobe: [], // Initialize with an empty wardrobe
        subscription: 'free' // Set default subscription
      });
      console.log("User document created successfully");
    } catch (error) {
      console.error("Error creating user document:", error);
    }
  } else {
    console.log("User document already exists");
  }

  return userRef;
};