//eslint-disable-next-line
import React, { useState, useEffect, useRef, useCallback } from "react";
import { db, auth, storage, getDoc, setDoc, doc, updateDoc, googleProvider, appleProvider, signInWithPopup } from "./Data/firebase";
import { getDocs, collection } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { Form, Button, Card, Container, Row, Col, Modal, Collapse, Alert } from "react-bootstrap";
import "./Css/App.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import AppNavbar from "./Bars/AppNavbar";
import ClothingForm from "./Tools/ClothingForm";
import WishlistModal from "./Modals/WishlistModal";
import { sendPasswordResetEmail, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import PropTypes from "prop-types";
import AuthModal from "./Modals/AuthModal";
import BottomBar from "./Bars/BottomBar";
import { createUserDocument } from './userUtils';
import axios from 'axios';

const Home = ({
  cart,
  setCart,
  wishlist,
  setWishlist,
  showCart,
  setShowCart,
  showWishlist,
  setShowWishlist,
  showAuthModal,
  setShowAuthModal,
  authMode,
  setAuthMode,
  shareableLink,
  setShareableLink,
  showShareModal,
  setShowShareModal,
  showPasswordResetModal,
  setShowPasswordResetModal,
  resetEmail,
  setResetEmail,
  user,
  userName,
  handleSignOut,
  handleUpdateUserName,
  handleSearchSubmit,
  handleSearchChange,
  showSupportForm,
  setShowSupportForm,
  userSubscription,
  setUserSubscription,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t, i18n } = useTranslation();
  const [showItemModal, setShowItemModal] = useState(false);

  const handleLogin = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log("User signed in:", userCredential.user);
      setShowAuthModal(false);
      fetchUserData(userCredential.user.uid); // Fetch user data after login
    } catch (error) {
      console.error("Error signing in:", error);
      alert(error.message);
    }
  };

  useEffect(() => {
    console.log('showSupportForm state changed:', showSupportForm);
  }, [showSupportForm]);

  const handleSignup = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      console.log("User signed up:", userCredential.user);
      setShowAuthModal(false); // Close the auth modal on successful signup
    } catch (error) {
      console.error("Error signing up:", error);
      alert(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("Google sign-in successful", result.user);
      
      // Create or get the user document
      await createUserDocument(result.user);
      
      setShowAuthModal(false);
      // Update user state and fetch user data
      fetchUserData(result.user.uid);
    } catch (error) {
      console.error("Error signing in with Google", error);
      alert(error.message);
    }
  };


  const handleAppleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, appleProvider);
      console.log("Apple sign-in successful", result.user);
      setShowAuthModal(false);
      // Update user state and fetch user data
      fetchUserData(result.user.uid);
    } catch (error) {
      console.error("Error signing in with Apple", error);
      alert(error.message);
    }
  };


  const [clothingItem, setClothingItem] = useState({
    category: "",
    color: "",
    material: "",
    brand: "",
    size: "",
    purchaseDate: "",
    price: "",
    season: [],
    style: [],
    quantity: 1,
  });
  const [image, setImage] = useState(null);
  const [items, setItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState({
    category: "",
    subcategory: "",
    value: "",
  });
  const [currentClothing, setCurrentClothing] = useState(null);
  const [showScroll, setShowScroll] = useState(false);
  const [visibleItem, setVisibleItem] = useState(null);
  const [collapsedCategories, setCollapsedCategories] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [itemLimit, setItemLimit] = useState(50);
  const [showUpgradeAlert, setShowUpgradeAlert] = useState(false);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseImageModal = () => {
    setSelectedImage(null);
  };
//eslint-disable-next-line
  const formRef = useRef(null);

  useEffect(() => {
    if (user) {
      fetchItems(user.uid);
    }
  }, [user]);

  const toggleVisibility = (id) => {
    if (visibleItem === id) {
      setVisibleItem(null); // Hide if currently visible
    } else {
      setVisibleItem(id); // Show the clicked item
    }
  };

  const saveToLocalStorage = (key, data) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  useEffect(() => {
    if (user) {
      fetchUserData(user.uid);
      fetchItems(user.uid);
    }
    //eslint-disable-next-line
  }, [user]);

  const fetchUserData = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserSubscription(userData.subscription || 'free');
        setItemLimit(userData.subscription === 'free' ? 50 : Infinity);
        // Update username using the handleUpdateUserName prop
        if (userData.displayName) {
          handleUpdateUserName(userData.displayName);
        }
      } else {
        console.log("No user document found, creating one");
        const newUser = auth.currentUser;
        await createUserDocument(newUser);
        // Set default username after creating document
        if (newUser.displayName) {
          handleUpdateUserName(newUser.displayName);
        } else {
          // If no display name, use email or a default value
          const defaultName = newUser.email ? newUser.email.split('@')[0] : 'User';
          handleUpdateUserName(defaultName);
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchItems = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        setItems(userDoc.data().wardrobe || []);
      } else {
        setItems([]);
      }
    } catch (error) {
      console.error("Error fetching items: ", error);
    }
  };

  // const loadFromLocalStorage = (key) => {
  //   const data = localStorage.getItem(key);
  //   return data ? JSON.parse(data) : [];
  // };
  // eslint-disable-next-line
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    Cookies.set("i18next", lng, { expires: 365 });
  };

  const handleShareCart = async () => {
    if (user && cart.length > 0) {
      try {
        const cartId = uuidv4();
        await setDoc(doc(db, "sharedCarts", cartId), {
          userId: user.uid,
          cart: cart,
        });

        const link = `https://main--wardrobev02.netlify.app/shared-cart/${cartId}`;
        console.log("Shareable link:", link);
        setShareableLink(link);
        setShowShareModal(true);
      } catch (error) {
        console.error("Error sharing cart:", error);
      }
    } else {
      alert("Please sign in and add items to your cart before sharing.");
    }
  };
  // eslint-disable-next-line
  function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = 0;
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
      alert("Shareable link copied to clipboard");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
      alert("Unable to copy to clipboard");
    }
    document.body.removeChild(textArea);
  } // eslint-disable-line no-unused-vars

  const handlePasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert(
        "Error sending password reset email. Please check the email address and try again."
      );
    }
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 300) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 300) {
        setShowScroll(false);
      }
    };

    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleCategory = (category) => {
    setCollapsedCategories((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  const handleChange = (e) => {
    setClothingItem({ ...clothingItem, [e.target.name]: e.target.value });
  };

  const handleSeasonChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setClothingItem((prevState) => ({ ...prevState, season: values }));
  };

  const handleStyleChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setClothingItem((prevState) => ({ ...prevState, style: values }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user) {
      if (items.length >= itemLimit) {
        setShowUpgradeAlert(true);
        return;
      }

      try {
        let imageUrl = clothingItem.imageUrl || "";
        if (image) {
          const storageRef = ref(storage, `images/${image.name}`);
          await uploadBytes(storageRef, image);
          imageUrl = await getDownloadURL(storageRef);
        }


        const updatedClothingItem = {
          ...clothingItem,
          season: clothingItem.season || [],
          style: clothingItem.style || [],
          imageUrl,
          id: currentClothing ? currentClothing.id : Date.now().toString(), // Simple unique ID
        };

        let userDoc = await getDoc(doc(db, "users", user.uid));
        let userWardrobe = userDoc.exists() ? userDoc.data().wardrobe : [];

        if (currentClothing) {
          userWardrobe = userWardrobe.map((item) =>
            item.id === currentClothing.id ? updatedClothingItem : item
          );
        } else {
          userWardrobe.push(updatedClothingItem);
        }
        await setDoc(doc(db, "users", user.uid), { wardrobe: userWardrobe });

        // Update state to reflect the changes immediately
        setItems(userWardrobe);

        // Clear the form
        setClothingItem({
          category: "",
          color: "",
          material: "",
          brand: "",
          size: "",
          purchaseDate: "",
          price: "",
          season: [],
          style: [],
          quantity: 1,
        });
        setImage(null);
        setCurrentClothing(null);

        alert("Item added successfully!");
      } catch (error) {
        console.error("Error adding item:", error);
      }
    } else {
      console.error("No image selected or user not signed in");
    }
  };

  const handleEdit = (item) => {
    setCurrentClothing(item);
    setClothingItem(item);
    setShowItemModal(true);
  };

  const handleAddClick = () => {
    console.log("handleAddClick called");
    setShowItemModal(true);
  };

//eslint-disable-next-line
  const handleAddEditClick = (item = null) => {
    if (item) {
      setCurrentClothing(item);
      setClothingItem(item);
    } else {
      setCurrentClothing(null);
      setClothingItem({
        category: "",
        color: "",
        material: "",
        brand: "",
        size: "",
        purchaseDate: "",
        price: "",
        season: [],
        style: [],
        quantity: 1,
      });
    }
    setShowItemModal(true);
  };
  //eslint-disable-next-line
  const handleCloseItemModal = () => {
    setShowItemModal(false);
    if (!currentClothing) {
      setClothingItem({
        category: "",
        color: "",
        material: "",
        brand: "",
        size: "",
        purchaseDate: "",
        price: "",
        season: [],
        style: [],
        quantity: 1,
      });
    }
  };

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleAddToCart = (item) => {
    const updatedCart = [...cart, item];
    setCart(updatedCart);
    saveToLocalStorage("cart", updatedCart);
  };

  useEffect(() => {
    const savedLanguage = Cookies.get("i18next");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const handleRemoveFromCart = async (id) => {
    // Update the local state
    const updatedCart = cart.filter((item) => item.id !== id);
    setCart(updatedCart);
    saveToLocalStorage("cart", updatedCart);
    // Update Firestore for shared carts
    const sharedCartsSnapshot = await getDocs(collection(db, "sharedCarts"));
    sharedCartsSnapshot.forEach(async (sharedCart) => {
      let sharedCartData = sharedCart.data();
      sharedCartData.cart = sharedCartData.cart.filter(
        (item) => item.id !== id
      );
      await updateDoc(doc(db, "sharedCarts", sharedCart.id), {
        cart: sharedCartData.cart,
      });
    });
  };

  const handleDelete = async (id) => {
    if (user) {
      try {
        // Fetch the user’s wardrobe
        const userDoc = await getDoc(doc(db, "users", user.uid));
        let userWardrobe = userDoc.exists() ? userDoc.data().wardrobe : [];
        // Log current wardrobe
        console.log("Current wardrobe:", userWardrobe);
        // Filter out the item to be deleted
        const itemToDelete = userWardrobe.find((item) => item.id === id);
        userWardrobe = userWardrobe.filter((item) => item.id !== id);

        // Log updated wardrobe
        console.log("Updated wardrobe after deletion:", userWardrobe);

        // Update the user’s wardrobe in Firestore
        await setDoc(doc(db, "users", user.uid), { wardrobe: userWardrobe });

        // Update local state
        setItems(userWardrobe);

        // Log deletion from ‘users’ collection
        console.log(`Deleting item with ID ${id} from 'users' collection`);

        // Also update shared carts
        const sharedCartsSnapshot = await getDocs(
          collection(db, "sharedCarts")
        );
        sharedCartsSnapshot.forEach(async (sharedCart) => {
          let sharedCartData = sharedCart.data();
          sharedCartData.cart = sharedCartData.cart.filter(
            (item) => item.id !== id
          );
          await updateDoc(doc(db, "sharedCarts", sharedCart.id), {
            cart: sharedCartData.cart,
          });
        });

        // Optionally delete the image from storage if needed
        if (itemToDelete.imageUrl) {
          const imageRef = ref(storage, itemToDelete.imageUrl);
          await deleteObject(imageRef);
        }

        // Log successful deletion
        console.log(
          `Item with ID ${id} successfully deleted from user wardrobe and storage`
        );
      } catch (error) {
        console.error("Error deleting item:", error);
      }
    }
  };

  const handleAddToWishlist = (item) => {
    const updatedWishlist = [...wishlist, item];
    setWishlist(updatedWishlist);
    saveToLocalStorage("wishlist", updatedWishlist);
  };

  const handleRemoveFromWishlist = (id) => {
    const updatedWishlist = wishlist.filter((item) => item.id !== id);
    setWishlist(updatedWishlist);
    saveToLocalStorage("wishlist", updatedWishlist);
  };

  const groupItemsByCategory = (items) => {
    return items.reduce((groupedItems, item) => {
      const category = item.category || "Uncategorized";
      if (!groupedItems[category]) {
        groupedItems[category] = [];
      }
      groupedItems[category].push(item);
      return groupedItems;
    }, {});
  };

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      const reader = new FileReader();
      reader.onload = (e) => {
        img.src = e.target.result;
        img.onload = () => {
          let width = img.width;
          let height = img.height;
          console.log(`Original dimensions: ${width}x${height}`);

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          console.log(`Resized dimensions: ${width}x${height}`);

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                console.log("Image resized successfully");
                const resizedFile = new File([blob], file.name, {
                  type: file.type,
                });
                resolve(resizedFile);
              } else {
                reject(new Error("Canvas is empty"));
              }
            },
            file.type,
            1
          );
        };
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    if (e.target.files[0]) {
      console.log("Original file:", e.target.files[0]);
      const resizedImage = await resizeImage(e.target.files[0], 700, 700);
      console.log("Resized file:", resizedImage);
      setImage(resizedImage);
    }
  };


  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  // eslint-disable-next-line
  const [searchQuery, setSearchQuery] = useState("");
  // eslint-disable-next-line
  const handleNext = () => {
    setStep(step + 1);
  };
  // eslint-disable-next-line
  const handleBack = () => {
    setStep(step - 1);
  };

  const handleSupportSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://app.imorganized.online/send-email.php', formData);
      if (response.data.status === 'success') {
        alert(t("thankYouContacting"));
        setShowSupportForm(false);
        setStep(1);
        setFormData({ name: "", email: "", message: "" });
      } else {
        throw new Error(response.data.message || 'Failed to send email');
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send your message. Please try again later.");
    }
  };

  const handleChangeSupportForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredItems = items.filter((item) => {
    const matchesCategory = filter.category
      ? item.category === filter.category
      : true;
    const matchesSubcategory = filter.subcategory
      ? Array.isArray(item[filter.subcategory])
        ? item[filter.subcategory].includes(filter.value)
        : item[filter.subcategory]
            ?.toString()
            .toLowerCase()
            .includes(filter.value.toLowerCase())
      : true;
    const matchesSearchQuery = searchQuery
      ? item.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.color.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.material.toLowerCase().includes(searchQuery.toLowerCase())
      : true;

    return matchesCategory && matchesSubcategory && matchesSearchQuery;
  });

  useEffect(() => {
    const uniqueCategories = [...new Set(items.map((item) => item.category))];
    setCategories(uniqueCategories);

    // Initialize collapsedCategories with all categories set to true (collapsed)
    const initialCollapsedCategories = uniqueCategories.reduce(
      (acc, category) => {
        acc[category] = true;
        return acc;
      },
      {}
    );
    setCollapsedCategories(initialCollapsedCategories);
  }, [items]);

  useEffect(() => {
    console.log("showItemModal changed:", showItemModal);
  }, [showItemModal]);

  Home.propTypes = {
    cart: PropTypes.array.isRequired,
    setCart: PropTypes.func.isRequired,
    wishlist: PropTypes.array.isRequired,
    setWishlist: PropTypes.func.isRequired,
    showCart: PropTypes.bool.isRequired,
    setShowCart: PropTypes.func.isRequired,
    showWishlist: PropTypes.bool.isRequired,
    setShowWishlist: PropTypes.func.isRequired,
    showAuthModal: PropTypes.bool.isRequired,
    setShowAuthModal: PropTypes.func.isRequired,
    authMode: PropTypes.string.isRequired,
    setAuthMode: PropTypes.func.isRequired,
    shareableLink: PropTypes.string,
    setShareableLink: PropTypes.func.isRequired,
    showShareModal: PropTypes.bool.isRequired,
    setShowShareModal: PropTypes.func.isRequired,
    showPasswordResetModal: PropTypes.bool.isRequired,
    setShowPasswordResetModal: PropTypes.func.isRequired,
    resetEmail: PropTypes.string,
    setResetEmail: PropTypes.func.isRequired,
    user: PropTypes.object,
    userName: PropTypes.string,
    handleSignOut: PropTypes.func.isRequired,
    handleUpdateUserName: PropTypes.func.isRequired,
    handleSearchSubmit: PropTypes.func.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    showSupportForm: PropTypes.bool.isRequired, // Add this line
    setShowSupportForm: PropTypes.func.isRequired, // Add this line
    userSubscription: PropTypes.string.isRequired,
    setUserSubscription: PropTypes.func.isRequired,
  };

  
  Home.defaultProps = {
    cart: [],
    wishlist: [],
    shareableLink: "",
    resetEmail: "",
    user: null,
    userName: "",
    userSubscription: 'free',
  };

  return (
    <Container>
      <AppNavbar
        user={user}
        userName={userName}
        cart={cart}
        wishlist={wishlist}
        handleSignOut={handleSignOut}
        handleUpdateUserName={handleUpdateUserName}
        setShowAuthModal={setShowAuthModal}
        setShowCart={setShowCart}
        setShowWishlist={setShowWishlist}
        setShowSupportForm={setShowSupportForm}
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        setAuthMode={setAuthMode}
      />
      <h1 className="my-4 text-center">{t("welcome")}</h1>
      {user ? (
        <>
          {showUpgradeAlert && (
        <Alert variant="warning" onClose={() => setShowUpgradeAlert(false)} dismissible>
          {t("upgradeAlertMessage")}
          {/* You might want to add a button here to navigate to the account settings page */}
        </Alert>
      )}

          
          <h2>{t("filters")}</h2>
          <Form>
            <Row>
              <Col md={4}>
                <Form.Group controlId="filterCategory">
                  <Form.Label>{t("category")}</Form.Label>
                  <Form.Control
                    as="select"
                    name="category"
                    value={filter.category}
                    onChange={handleFilterChange}
                  >
                    <option value="">{t("all")}</option>
                    {categories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="filterSubcategory">
                  <Form.Label>{t("subcategory")}</Form.Label>
                  <Form.Control
                    as="select"
                    name="subcategory"
                    value={filter.subcategory}
                    onChange={handleFilterChange}
                  >
                    <option value="">{t("all")}</option>
                    <option value="color">{t("color")}</option>
                    <option value="material">{t("material")}</option>
                    <option value="brand">{t("brand")}</option>
                    <option value="size">{t("size")}</option>
                    <option value="purchaseDate">{t("purchaseDate")}</option>
                    <option value="price">{t("price")}</option>
                    <option value="season">{t("season")}</option>
                    <option value="style">{t("style")}</option>
                    <option value="quantity">{t("quantity")}</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              {filter.subcategory && (
                <Col md={4}>
                  <Form.Group controlId={`filter${filter.subcategory}`}>
                    <Form.Label>{t(filter.subcategory)}</Form.Label>
                    {filter.subcategory === "season" ||
                    filter.subcategory === "style" ? (
                      <Form.Control
                        as="select"
                        name="value"
                        value={filter.value}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t("all")}</option>
                        {Array.from(
                          new Set(
                            items.flatMap((item) => item[filter.subcategory])
                          )
                        ).map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </Form.Control>
                    ) : (
                      <Form.Control
                        as="select"
                        name="value"
                        value={filter.value}
                        onChange={handleFilterChange}
                      >
                        <option value="">{t("all")}</option>
                        {Array.from(
                          new Set(items.map((item) => item[filter.subcategory]))
                        ).map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Form>
          <h2 className="my-4">{t("clothingItems")}</h2>
          {Object.entries(groupItemsByCategory(filteredItems)).map(
            ([category, items]) => (
              <div key={category} className="category-section">
                <h3 style={{ cursor: "pointer" }}>
                  {category}
                  <Button
                    variant="link"
                    onClick={() => toggleCategory(category)}
                    aria-expanded={!collapsedCategories[category]}
                    aria-controls={`collapse-${category}`}
                    className="ml-2"
                    style={{ fontSize: "1.2rem", color: "black" }}
                  >
                    {collapsedCategories[category] ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </Button>
                </h3>

                <Collapse in={!collapsedCategories[category]}>
                  <div id={`collapse-${category}`}>
                    <Row>
                      {items.map((item) => (
                        <Col md={4} key={item.id}>
                          <Card className="mb-4 shadow-sm">
                            <Card.Img
                              variant="top"
                              src={item.imageUrl}
                              className="card-img-top"
                              onClick={() => toggleVisibility(item.id)}
                              style={{ cursor: "pointer" }}
                            />
                            <Card.Body>
                              <Card.Title>{item.category}</Card.Title>
                              {visibleItem === item.id && (
                                <Card.Text>
                                  <strong>{t("color")}:</strong> {item.color}
                                  <br />
                                  <strong>{t("material")}:</strong>{" "}
                                  {item.material}
                                  <br />
                                  <strong>{t("brand")}:</strong> {item.brand}
                                  <br />
                                  <strong>{t("size")}:</strong> {item.size}
                                  <br />
                                  <strong>{t("purchaseDate")}:</strong>{" "}
                                  {item.purchaseDate}
                                  <br />
                                  <strong>{t("price")}:</strong> ${item.price}
                                  <br />
                                  <strong>{t("season")}:</strong>{" "}
                                  {item.season ? item.season.join(", ") : "N/A"}
                                  <br />
                                  <strong>{t("style")}:</strong>{" "}
                                  {item.style ? item.style.join(", ") : "N/A"}
                                  <br />
                                  <strong>{t("quantity")}:</strong>{" "}
                                  {item.quantity}
                                </Card.Text>
                              )}
                              <Button
                                className="btn-custom-success"
                                onClick={() => handleAddToCart(item)}
                              >
                                {t("addToCart")}
                              </Button>
                              <Button
                                className="btn-custom-info ml-2"
                                onClick={() => handleAddToWishlist(item)}
                              >
                                {t("addToWishlist")}
                              </Button>
                              <Button
                                className="btn-custom-edit ml-2"
                                onClick={() => handleEdit(item)}
                              >
                                {t("edit")}
                              </Button>
                              <Button
                                className="btn-custom-danger ml-2 button"
                                onClick={() => handleDelete(item.id)}
                                data-text={t("delete")}
                              >
                                <svg viewBox="0 0 448 512" className="svgIcon">
                                  <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                                </svg>
                              </Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Collapse>
              </div>
            )
          )}
        </>
      ) : (
        <p>{t("pleaseSignIn")}</p>
      )}

<Modal 
  show={showItemModal} 
  onHide={() => setShowItemModal(false)}
  centered
  dialogClassName="support-modal-dialog"
>
        <Modal.Body>
          <div className="form-container">
            <h2 className="modal-title">
              {currentClothing ? t("editItem") : t("addNewItem")}
            </h2>
            <ClothingForm
              clothingItem={clothingItem}
              setClothingItem={setClothingItem}
              handleChange={handleChange}
              handleSeasonChange={handleSeasonChange}
              handleStyleChange={handleStyleChange}
              handleImageChange={handleImageChange}
              setImage={setImage}
              handleSubmit={(e) => {
                handleSubmit(e);
                setShowItemModal(false);
              }}
              currentClothing={currentClothing}
              t={t}
              />
              <Button onClick={() => setShowItemModal(false)} className="form-submit-btn">
                {t("close")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

      <BottomBar
        onAddClick={handleAddClick}
        user={user}
        setShowAuthModal={setShowAuthModal}
        setAuthMode={setAuthMode}
      />


      <AuthModal
        showAuthModal={showAuthModal}
        setShowAuthModal={setShowAuthModal}
        authMode={authMode}
        setAuthMode={setAuthMode}
        handleLogin={handleLogin}
        handleSignup={handleSignup}
        handlePasswordReset={handlePasswordReset}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handleGoogleSignIn={handleGoogleSignIn}
        handleAppleSignIn={handleAppleSignIn}
      />

<Modal
        show={showSupportForm}
        onHide={() => setShowSupportForm(false)}
        centered
        dialogClassName="support-modal-dialog"
      >
        <div className="form-container">
          <Form onSubmit={handleSupportSubmit} className="form">
            <Form.Group controlId="formName" className="form-group">
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChangeSupportForm}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="form-group">
              <Form.Label>{t("email")}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChangeSupportForm}
                required
              />
            </Form.Group>
            <Form.Group controlId="formMessage" className="form-group">
              <Form.Label>{t("message")}</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={formData.message}
                onChange={handleChangeSupportForm}
                rows={3}
                required
              />
            </Form.Group>
            <Button type="submit" className="form-submit-btn">
              {t("submit")}
            </Button>
          </Form>
        </div>
      </Modal>


<Modal
  show={showCart}
  onHide={() => setShowCart(false)}
  dialogClassName="support-modal-dialog"
>
  <Modal.Body>
    <div className="form-container">
      <h2 className="modal-title">{t("todayOutfit")}</h2>
      {cart.length > 0 ? (
        cart.map((item) => (
          <div key={item.id} className="cart-item">
            <img
              src={item.imageUrl}
              alt={item.category}
              className="cart-item-image"
              onClick={() => handleImageClick(item.imageUrl)}
              style={{ cursor: "pointer" }}
            />
            <div className="cart-item-details">
              <h3 className="item-category">{item.category}</h3>
              <p>
                <strong>{t("color")}:</strong> {item.color}
                <br />
                <strong>{t("material")}:</strong> {item.material}
                <br />
                <strong>{t("brand")}:</strong> {item.brand}
                <br />
                <strong>{t("size")}:</strong> {item.size}
                <br />
                <strong>{t("purchaseDate")}:</strong> {item.purchaseDate}
                <br />
                <strong>{t("price")}:</strong> ${item.price}
                <br />
                <strong>{t("season")}:</strong>{" "}
                {item.season ? item.season.join(", ") : "N/A"}
                <br />
                <strong>{t("style")}:</strong>{" "}
                {item.style ? item.style.join(", ") : "N/A"}
                <br />
                <strong>{t("quantity")}:</strong> {item.quantity}
              </p>
              <div className="cart-item-actions">
                <Button
                  variant="danger"
                  onClick={() => handleRemoveFromCart(item.id)}
                  className="btn-cart-primary button"
                  data-text={t("remove")}
                >
                  <svg viewBox="0 0 448 512" className="svgIcon">
                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                  </svg>
                </Button>
                <Button
                  className="share-cart-button ml-2 btn-cart-secondary"
                  onClick={handleShareCart}
                >
                  {t("shareCart")}
                </Button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>{t("noItemsCart")}</p>
      )}
      <Button
        variant="secondary"
        onClick={() => setShowCart(false)}
        className="btn-cart-secondary form-submit-btn"
      >
        {t("close")}
      </Button>
    </div>
  </Modal.Body>
</Modal>

      {/* Modal for showing larger image */}
      <Modal show={!!selectedImage} onHide={handleCloseImageModal} centered>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Larger View"
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>

      <WishlistModal
        show={showWishlist}
        handleClose={() => setShowWishlist(false)}
        wishlist={wishlist}
        handleRemoveFromWishlist={handleRemoveFromWishlist}
      />

      {/* Back to Top Button */}
      <button
        className="scrollTop"
        onClick={scrollTop}
        style={{ display: showScroll ? "flex" : "none" }}
      >
        &#8679;
      </button>
    </Container>
  );
};
export default Home;
