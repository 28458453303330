import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import AuthForm from "./AuthForm";

const AuthModal = ({
  showAuthModal,
  setShowAuthModal,
  handleLogin,
  handleSignup,
  handlePasswordReset,
  handleGoogleSignIn,
  handleAppleSignIn,
}) => {
  return (
    <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)} centered>
      <Modal.Body>
        <AuthForm
          onLogin={handleLogin}
          onSignup={handleSignup}
          onForgotPassword={() => {
            setShowAuthModal(false);
            handlePasswordReset();
          }}
          onGoogleSignIn={handleGoogleSignIn}
          onAppleSignIn={handleAppleSignIn}
        />
      </Modal.Body>
    </Modal>
  );
}

AuthModal.propTypes = {
  showAuthModal: PropTypes.bool.isRequired,
  setShowAuthModal: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleSignup: PropTypes.func.isRequired,
  handlePasswordReset: PropTypes.func.isRequired,
  handleGoogleSignIn: PropTypes.func.isRequired,
  handleAppleSignIn: PropTypes.func.isRequired,
};

export default AuthModal;