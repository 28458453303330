import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../Data/firebase';
import { getDoc, doc } from 'firebase/firestore';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SharedCart = () => {
  const { cartId } = useParams();
  const [cart, setCart] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSharedCart = async () => {
      try {
        const cartDoc = await getDoc(doc(db, 'sharedCarts', cartId));
        if (cartDoc.exists()) {
          setCart(cartDoc.data().cart);
        } else {
          console.error('No shared cart found');
        }
      } catch (error) {
        console.error('Error fetching shared cart:', error);
      }
    };

    fetchSharedCart();
  }, [cartId]);

  return (
    <Container>
      <h1>{t('sharedCart')}</h1>
      <Row>
        {cart.map(item => (
          <Col md={4} key={item.id}>
            <Card className="mb-4 shadow-sm">
              <Card.Img variant="top" src={item.imageUrl} />
              <Card.Body>
                <Card.Title>{item.category}</Card.Title>
                <Card.Text>
                  <strong>{t('color')}:</strong> {item.color}<br />
                  <strong>{t('material')}:</strong> {item.material}<br />
                  <strong>{t('brand')}:</strong> {item.brand}<br />
                  <strong>{t('size')}:</strong> {item.size}<br />
                  <strong>{t('purchaseDate')}:</strong> {item.purchaseDate}<br />
                  <strong>{t('price')}:</strong> ${item.price}<br />
                  <strong>{t('season')}:</strong> {item.season ? item.season.join(', ') : 'N/A'}<br />
                  <strong>{t('style')}:</strong> {item.style ? item.style.join(', ') : 'N/A'}<br />
                  <strong>{t('quantity')}:</strong> {item.quantity}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default SharedCart;