import React, { useState, useEffect } from "react";
import { auth, getDoc, doc, db, signOut, updateDoc } from "./Data/firebase";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./Css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Home";
import SharedCart from "./Tools/SharedCart";
import AccountSettings from "./Account/AccountSettings";
import BottomBar from "./Bars/BottomBar";

function App() {
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState("signIn");
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState("");
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareableLink, setShareableLink] = useState("");
  const [showSupportForm, setShowSupportForm] = useState(false);
  const [userSubscription, setUserSubscription] = useState("free");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const userDoc = await getDoc(doc(db, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.name || user.email);
          setUserSubscription(userData.subscription || "free");
        }
      } else {
        setUser(null);
        setUserName("");
        setUserSubscription("free");
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setUserName("");
      setUserSubscription("free");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleUpdateUserName = async (newUsername) => {
    if (user) {
      try {
        await updateDoc(doc(db, "users", user.uid), { name: newUsername });
        setUserName(newUsername);
        // Update the user object to reflect the new username
        setUser(prevUser => ({ ...prevUser, displayName: newUsername }));
      } catch (error) {
        console.error("Error updating username:", error);
        alert("Failed to update username");
      }
    }
  };

  const handleUpdateSubscription = async (newSubscription) => {
    if (user) {
      try {
        await updateDoc(doc(db, "users", user.uid), { subscription: newSubscription });
        setUserSubscription(newSubscription);
        alert("Subscription updated successfully!");
      } catch (error) {
        console.error("Error updating subscription:", error);
        alert("Failed to update subscription");
      }
    }
  };

  return (
    <div className="App">
      <Router>
        <Container fluid>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  cart={cart}
                  setCart={setCart}
                  wishlist={wishlist}
                  setWishlist={setWishlist}
                  showCart={showCart}
                  setShowCart={setShowCart}
                  showWishlist={showWishlist}
                  setShowWishlist={setShowWishlist}
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                  authMode={authMode}
                  setAuthMode={setAuthMode}
                  shareableLink={shareableLink}
                  setShareableLink={setShareableLink}
                  showShareModal={showShareModal}
                  setShowShareModal={setShowShareModal}
                  showPasswordResetModal={showPasswordResetModal}
                  setShowPasswordResetModal={setShowPasswordResetModal}
                  resetEmail={resetEmail}
                  setResetEmail={setResetEmail}
                  user={user}
                  userName={userName}
                  handleSignOut={handleSignOut}
                  handleUpdateUserName={handleUpdateUserName}
                  handleSearchSubmit={() => {}}
                  handleSearchChange={() => {}}
                  fetchItems={() => {}}
                  showSupportForm={showSupportForm}
                  setShowSupportForm={setShowSupportForm}
                  userSubscription={userSubscription}
                  BottomBar={BottomBar}
                />
              }
            />
            <Route path="/shared-cart/:cartId" element={<SharedCart />} />
            <Route 
              path="/account-settings" 
              element={
                <AccountSettings 
                  user={user}
                  userName={userName}
                  onUsernameUpdate={handleUpdateUserName}
                  userSubscription={userSubscription}
                  handleUpdateUserName={handleUpdateUserName}
                  handleUpdateSubscription={handleUpdateSubscription}
                />
              } 
            />
          </Routes>
        </Container>
      </Router>
    </div>
  );
}

export default App;