import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Plus, Person } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const BottomBar = ({ onAddClick, user, setShowAuthModal, setAuthMode }) => {
  const navigate = useNavigate();

  const handleAccountSettings = () => {
    if (user) {
      navigate('/account-settings');
    } else {
      setAuthMode('login');
      setShowAuthModal(true);
    }
  };

  const handleAddItem = () => {
    if (user) {
      onAddClick();
    } else {
      setAuthMode('login');
      setShowAuthModal(true);
    }
  };

  return (
    <Container
      fluid
      className="fixed-bottom bottom-bar"
    >
      <div className="d-flex justify-content-between align-items-center h-100">
        <div className="flex-grow-1"></div>
        <Button
          variant="primary"
          className="rounded-circle add-button"
          onClick={handleAddItem}
        >
          <Plus size={24} />
        </Button>
        <Button
          variant="link"
          className="account-settings-button"
          onClick={handleAccountSettings}
        >
          <Person size={24} />
        </Button>
      </div>
    </Container>
  );
};

BottomBar.propTypes = {
  onAddClick: PropTypes.func.isRequired,
  user: PropTypes.object,
  setShowAuthModal: PropTypes.func.isRequired,
  setAuthMode: PropTypes.func.isRequired,
};

export default BottomBar;