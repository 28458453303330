import React from "react";
import Select from "react-select";
import { Form, Button, Row, Col } from "react-bootstrap";

const ClothingForm = ({
  clothingItem,
  setClothingItem,
  handleChange,
  handleSeasonChange,
  handleStyleChange,
  handleImageChange,
  handleSubmit,
  currentClothing,
  t,
  formRef,
  setImage,
}) => {
  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;
        img.onload = () => {
          let width = img.width;
          let height = img.height;

          console.log(`Original dimensions: ${width}x${height}`);

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          console.log(`Resized dimensions: ${width}x${height}`);

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob) {
                console.log("Image resized successfully");
                const resizedFile = new File([blob], file.name, {
                  type: file.type,
                });
                resolve(resizedFile);
              } else {
                reject(new Error("Canvas is empty"));
              }
            },
            file.type,
            1
          );
        };
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChangeInternal = async (e) => {
    if (e.target.files[0]) {
      console.log("Original file:", e.target.files[0]);
      const resizedImage = await resizeImage(e.target.files[0], 700, 700);
      console.log("Resized file:", resizedImage);
      setImage(resizedImage);
    }
  };

  const handleClearDate = () => {
    handleChange({ target: { name: "purchaseDate", value: "" } });
  };

  return (
    <Form onSubmit={handleSubmit} className="mb-4" ref={formRef}>
      <Row>
        <Col md={6} lg={4}>
          <Form.Group controlId="category" className="category-field">
            <Form.Label>{t("category")}</Form.Label>
            <Form.Control
              type="text"
              name="category"
              value={clothingItem.category}
              onChange={handleChange}
              placeholder="🗂️"
            />
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group controlId="color" className="category-field">
            <Form.Label>{t("color")}</Form.Label>
            <Form.Control
              type="text"
              name="color"
              value={clothingItem.color}
              onChange={handleChange}
              placeholder="🎨"
            />
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group controlId="material" className="category-field">
            <Form.Label>{t("material")}</Form.Label>
            <Form.Control
              type="text"
              name="material"
              value={clothingItem.material}
              onChange={handleChange}
              placeholder="🧵"
            />
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group controlId="brand" className="category-field">
            <Form.Label>{t("brand")}</Form.Label>
            <Form.Control
              type="text"
              name="brand"
              value={clothingItem.brand}
              onChange={handleChange}
              placeholder="🏷️"
            />
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group controlId="size" className="category-field">
            <Form.Label>{t("size")}</Form.Label>
            <Form.Control
              type="text"
              name="size"
              value={clothingItem.size}
              onChange={handleChange}
              placeholder="📏"
            />
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group controlId="purchaseDate" className="category-field">
            <Form.Label>{t("purchaseDate")}</Form.Label>
            <div style={{ position: "relative" }}>
              <Form.Control
                type="date"
                name="purchaseDate"
                value={clothingItem.purchaseDate}
                onChange={handleChange}
                style={{ paddingRight: "30px" }}
              />
              {clothingItem.purchaseDate && (
                <Button
                  variant="outline-secondary"
                  onClick={handleClearDate}
                  style={{
                    position: "absolute",
                    right: "5px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    padding: "0 5px",
                  }}
                >
                  X
                </Button>
              )}
            </div>
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group controlId="price" className="category-field">
            <Form.Label>{t("price")}</Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={clothingItem.price}
              onChange={handleChange}
              placeholder="💰"
            />
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group controlId="season" className="category-field">
            <Form.Label>{t("season")}</Form.Label>
            <Select
              isMulti
              options={[
                { value: "summer", label: t("summer") },
                { value: "winter", label: t("winter") },
                { value: "midseason", label: t("midseason") },
              ]}
              value={clothingItem.season.map((s) => ({
                value: s,
                label: t(s),
              }))}
              onChange={handleSeasonChange}
            />
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group controlId="style" className="category-field">
            <Form.Label>{t("style")}</Form.Label>
            <Select
              isMulti
              options={[
                { value: "business", label: t("business") },
                { value: "casual", label: t("casual") },
                { value: "businesscasual", label: t("business-casual") },
                { value: "streetware", label: t("streetware") },
                { value: "elegant", label: t("elegant") },
                { value: "party", label: t("party") },
              ]}
              value={clothingItem.style.map((s) => ({ value: s, label: t(s) }))}
              onChange={handleStyleChange}
            />
          </Form.Group>
        </Col>
        <Col md={6} lg={4}>
          <Form.Group controlId="quantity" className="category-field">
            <Form.Label>{t("quantity")}</Form.Label>
            <Form.Control
              type="number"
              name="quantity"
              value={clothingItem.quantity}
              onChange={handleChange}
              min="1"
              placeholder="🔢"
            />
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group controlId="image" className="category-field mt-3">
            <Form.Label>{t("uploadImage")}</Form.Label>
            <Form.Control type="file" onChange={handleImageChangeInternal} />
          </Form.Group>
        </Col>
      </Row>
      <Button className="btn-custom-primary mt-3" type="submit">
        {currentClothing ? "🔄" : "➕"}
      </Button>
    </Form>
  );
};

export default ClothingForm;
