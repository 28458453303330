import React, { useState, useEffect } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import { auth, db } from '../Data/firebase';
import { updatePassword, updateEmail, updateProfile } from 'firebase/auth';
//eslint-disable-next-line
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { createUserDocument } from '../userUtils';


const AccountSettings = ({ onUsernameUpdate }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [subscription, setSubscription] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { t } = useTranslation();


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        setEmail(user.email);
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUsername(userDoc.data().name || '');
          setSubscription(userDoc.data().subscription || 'Free');
        }
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, []);

  const handleEmailChange = async (e) => {
    e.preventDefault();
    try {
      await updateEmail(auth.currentUser, email);
      setSuccess('Email updated successfully');
    } catch (error) {
      setError('Failed to update email: ' + error.message);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await updatePassword(auth.currentUser, newPassword);
      setSuccess('Password updated successfully');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setError('Failed to update password: ' + error.message);
    }
  };

  const handleUsernameChange = async (e) => {
    e.preventDefault();
    if (!user) return;

    try {
      // Ensure user document exists
      await createUserDocument(user);

      // Update in Firestore
      await setDoc(doc(db, 'users', user.uid), { displayName: username }, { merge: true });

      // Update in Firebase Auth
      await updateProfile(user, { displayName: username });

      // Update local state
      setUser({ ...user, displayName: username });

      // Notify parent component
      if (onUsernameUpdate) {
        onUsernameUpdate(username);
      }

      setSuccess('Username updated successfully');
    } catch (error) {
      console.error('Error updating username:', error);
      setError('Failed to update username: ' + error.message);
    }
  };


  const handleBack = () => {
    navigate('/');
  };


  if (!user) return <div>{t("logInForAccount")}</div>;

  return (
    <Container>
      <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
        <IconButton onClick={handleBack} aria-label="back to app">
          <ArrowBackIcon />
        </IconButton>
      </div>
      <h1>{t("accountSettings")}</h1>
      <Card>
        <Card.Body>
          <h2>{t("username")}</h2>
          <Form onSubmit={handleUsernameChange}>
        <Form.Group>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>
        <Button type="submit">{t("updateUsername")}</Button>
      </Form>
          <h2>E-mail</h2>
          <Form onSubmit={handleEmailChange}>
            <Form.Group>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button type="submit">{t("updateMail")}</Button>
          </Form>
          <h2>{t("changePassword")}</h2>
          <Form onSubmit={handlePasswordChange}>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
            <Button type="submit">{t("updatePassword")}</Button>
          </Form>
          <h2>{t("sub")}</h2>
          <p> {t("currentsub")} {subscription}</p>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
        </Card.Body>
      </Card>
    </Container>
  );
};

AccountSettings.propTypes = {
  onUsernameUpdate: PropTypes.func
};

export default AccountSettings;