import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Menu, MenuItem as MenuItemMUI, Typography } from '@mui/material';
import { ReactComponent as Logo } from './Navbar_Logo/logo.svg';
import { useTranslation } from 'react-i18next';
import FlagIcon from 'react-flagkit';
import Cookies from 'js-cookie';
import Dropdown from 'react-bootstrap/Dropdown';
import { getAuth } from 'firebase/auth';
import "../Css/AppNavbar.css";

const AppNavbar = ({
  user,
  userName,
  cart,
  wishlist,
  handleSignOut,
  setShowAuthModal,
  setShowCart,
  setShowWishlist,
  setShowSupportForm,
  handleSearchSubmit,
  handleSearchChange,
  setAuthMode,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUsername, setShowUsername] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);


  const { t, i18n } = useTranslation();
  // eslint-disable-next-line
  const auth = getAuth();

  useEffect(() => {
    if (user) {
      const interval = setInterval(() => {
        setShowUsername((prev) => !prev);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [user]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMenuItemClick = (callback) => {
    return () => {
      callback();
      if (window.innerWidth < 960) {
        setMenuOpen(false);
      }
    };
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    Cookies.set("i18next", lng, { expires: 365 });
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 2 }}>
      <Container maxWidth="lg">
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '999px',
            backdropFilter: 'blur(24px)',
            maxHeight: 70,
            border: '1px solid',
            borderColor: 'divider',
            bgcolor: 'hsla(220, 60%, 99%, 0.6)',
            boxShadow:
              '0 1px 2px hsla(210, 0%, 0%, 0.05), 0 2px 12px hsla(210, 100%, 80%, 0.5)',
            ...theme.applyStyles('dark', {
              bgcolor: 'hsla(220, 0%, 0%, 0.7)',
              boxShadow:
                '0 1px 2px hsla(210, 0%, 0%, 0.5), 0 2px 12px hsla(210, 100%, 25%, 0.3)',
            }),
          })}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Logo style={{ height: 70, width: 70 }} />
            <Typography
              sx={{
                marginLeft: 2,
                color: 'black',
                fontFamily: '"Nothing You Could Do", cursive !important',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: 24,
              }}
            >
              {user ? (showUsername ? `I'm ${userName}` : t("imOrganized")) : t("imOrganized")}
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 2, marginLeft: 'auto' }}>
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={handleMenuItemClick(() => setShowSupportForm(true))}
            >
              {t("support")}
            </Button>
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={handleMenuItemClick(() => setShowCart(true))}
            >
              {t("cart")} ({cart.length})
            </Button>
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={handleMenuItemClick(() => setShowWishlist(true))}
            >
              {t("wishlist")} ({wishlist.length})
            </Button>
            <Dropdown>
              <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                {t("language")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeLanguage("en")}>
                  <FlagIcon country="GB" size={24} /> English
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("es")}>
                  <FlagIcon country="ES" size={24} /> Español
                </Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage("de")}>
                  <FlagIcon country="DE" size={24} /> Deutsch
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {user ? (
              <>
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  onClick={handleMenuOpen}
                >
                  {userName}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItemMUI onClick={handleSignOut}>{t("signOut")}</MenuItemMUI>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  onClick={() => {
                    setAuthMode('login');
                    setShowAuthModal(true);
                  }}
                >
                  {t("signIn")}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setAuthMode('signup');
                    setShowAuthModal(true);
                  }}
                >
                  {t("signUp")}
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, marginLeft: 'auto' }}>
            <IconButton aria-label="Menu button" onClick={toggleMenu}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={menuOpen} onClose={toggleMenu}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <IconButton onClick={toggleMenu}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
                <MenuItem onClick={handleMenuItemClick(() => setShowSupportForm(true))}>
                  {t("support")}
                </MenuItem>
                <MenuItem onClick={handleMenuItemClick(() => setShowCart(true))}>
                  {t("cart")} ({cart.length})
                </MenuItem>
                <MenuItem onClick={handleMenuItemClick(() => setShowWishlist(true))}>
                  {t("wishlist")} ({wishlist.length})
                </MenuItem>
                <MenuItem>
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-light" id="dropdown-basic" style={{ color: 'black' }}>
                      {t("language")}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => changeLanguage("en")}>
                        <FlagIcon country="GB" size={24} /> English
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => changeLanguage("es")}>
                        <FlagIcon country="ES" size={24} /> Español
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => changeLanguage("de")}>
                        <FlagIcon country="DE" size={24} /> Deutsch
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </MenuItem>
                {user ? (
                  <>
                    <MenuItem onClick={handleMenuItemClick(handleSignOut)}>
                      {t("signOut")}
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={handleMenuItemClick(() => {
                          setAuthMode('signup');
                          setShowAuthModal(true);
                        })}
                      >
                        {t("signUp")}
                      </Button>
                    </MenuItem>
                    <MenuItem>
                      <Button
                        color="primary"
                        variant="outlined"
                        fullWidth
                        onClick={handleMenuItemClick(() => {
                          setAuthMode('login');
                          setShowAuthModal(true);
                        })}
                      >
                        {t("signIn")}
                      </Button>
                    </MenuItem>
                  </>
                )}
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

AppNavbar.propTypes = {
  user: PropTypes.object,
  userName: PropTypes.string,
  cart: PropTypes.array,
  wishlist: PropTypes.array,
  handleSignOut: PropTypes.func.isRequired,
  setShowAuthModal: PropTypes.func.isRequired,
  setShowCart: PropTypes.func.isRequired,
  setShowWishlist: PropTypes.func.isRequired,
  setShowSupportForm: PropTypes.func.isRequired,
  handleSearchSubmit: PropTypes.func,
  handleSearchChange: PropTypes.func,
  setAuthMode: PropTypes.func.isRequired,
};

AppNavbar.defaultProps = {
  cart: [],
  wishlist: [],
};

export default AppNavbar;